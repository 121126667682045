<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="kiosk-channels-page">
    <b-card title="Manage Channels">
      <b-card-text>This page displays all available Purple Play channel's, regardless of id you have them assigned to a group or not. You can click into these channels to manage the videos shown. </b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="d-flex justify-content-end mr-1">

          <!-- Group Assigned -->
          <b-col cols="12" md="3">
            <div>
              <label>Assigned to a group?</label>
              <b-form-select @change="getMoreChannels(1)" v-model="filters.group_assigned">
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option value="true">Yes</b-form-select-option>
                <b-form-select-option value="false">No</b-form-select-option>
              </b-form-select>
            </div>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div>
              <label>Search</label>
              <b-input-group>
                <b-form-input
                  @keydown.enter="getMoreChannels(1)"
                  v-model="filters.search"
                  class="d-inline-block"
                  placeholder="Search by channel name..."
                />
                <b-input-group-append>
                  <b-button @click="getMoreChannels(1)">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- Table -->
      <section>
        <b-table
          class="data-table"
          hover
          :items="channels"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(image_url)="data">
            <div>
              <b-avatar
                size="40"
                variant="light-primary"
                :src="data.item.image_url"
                class="badge-minimal"
              />
            </div>
          </template>

          <template #cell(name)="data">
            <RouterLink :to="{name: 'purple-play-channel-details', params: {id: data.item.id}}">
              {{ data.item.name }}
            </RouterLink>
          </template>

          <template #cell(channel_id)="data">
            <a :href="data.item.channel_id | formatYoutubeChannelUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
              {{ data.item.channel_id }}
            </a>
          </template>

          <template #cell(actions)="data">
            <RouterLink
              class="ml-1"
              variant="primary"
              size="sm"
              :to="{name: 'purple-play-channel-details', params: {id: data.item.id}}"
            >
              Manage
            </RouterLink>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreChannels" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p>No channels found.</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import helperService from '@/services/HelperService';
import { BAvatar } from 'bootstrap-vue';
import channelService from '../../services/YoutubeChannelService';

export default {
  name: 'PurplePlayChannels',
  components: {
    BAvatar,
  },
  data() {
    return {
      headers: [
        {
          key: 'image_url',
          label: 'Thumbnail',
        },
        {
          key: 'name',
          label: 'Channel Name',
        },
        {
          key: 'channel_id',
          label: 'Channel ID',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      channels: [],
      filters: {
        page: 0,
        page_size: 25,
        search: '',
        group_assigned: '',
      },
      currentPage: 1,
      total_count: 0,
    };
  },
  mounted() {
    this.getChannels();
  },
  methods: {
    refreshChannels() {
      this.getMoreChannels(1);
    },
    getMoreChannels(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getChannels();
    },
    async getChannels() {
      try {
        const { data } = await channelService.getChannels(this.filters)
        this.channels = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch channels, please refresh and try again')
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };
      this.refreshChannels()
    },
  },
};
</script>
